import React from 'react'
import bgImage from "@metronic/_metronic/assets/media/auth/bg6.jpg"
import {Card, CardBody, Col, Row} from "reactstrap"
import errorImg from "@metronic/assets/images/404-error.png"

const bgPosition = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundSize: "cover",
  zIndex: '0'
}

const overlay = {
  position: 'absolute',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
  zIndex: '1'
}

const ErrorPageContent = () => {
  return (
    <>
      <div className="d-flex flex-column flex-column-fluid position-relative">
        <img
          alt='bg'
          className="img-fluid theme-light-show w-100 h-100"
          src={bgImage}
          style={bgPosition}
        />
        <div style={overlay}></div>
        <Row className="justify-content-center align-items-center position-relative z-index-2 h-100">
          <Col sm={10} md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-10">
                <div className="text-center mt-2">
                  <img alt="img" src={errorImg} className="img-fluid w-300px h-auto"/>
                  <h1 className="text-primary mb-4">Oops !</h1>
                  <h4 className="text-uppercase">Sorry, Page not Found 😭</h4>
                  <p className="text-muted mb-4">The page you are looking for not available!</p>
                </div>
                <div className="p-2 mt-4">
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default ErrorPageContent
