import React, { Component } from 'react'
import {router} from "@inertiajs/react"
import errorImg from "@metronic/assets/images/404-error.png"
import {Card, CardBody} from "reactstrap";

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    errorData: null
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ hasError: true, errorData: error })
  }

  componentDidUpdate(prevProps) {
    if (this.props.urlKey !== prevProps.urlKey) {
      this.setState({ hasError: false, errorData: null })
    }
  }

  render() {
    if (this.state.errorData) {
      console.log(this.state.errorData);
      return (
        <div className='w-100 vh-100 d-flex flex-column justify-content-center bg-light-subtle align-items-center px-3'>
          <Card>
            <CardBody className="text-center p-1 p-sm-5">
              <img alt="img" src={errorImg} className="img-fluid w-300px h-auto"/>
              <h2 className="text-center text-danger">
                Oops... Something went wrong...
                <p className='fw-normal pt-2 px-10'>
                  {this.state?.errorData?.message
                    ? `Error: "${this.state?.errorData?.message}"`
                    : 'Some error without information...'
                  }
                </p>
                <p className="fw-normal text-gray-600 fs-4">
                  Please, try reloading the page. If the issue persists, contact support.
                </p>
                <div className='mt-3'>
                  <button
                    className="btn btn-sm fw-bold btn-warning mb-3 me-3"
                    onClick={() => {
                      window.location.reload()
                    }}
                  >
                    Reload Page
                  </button>
                  <button
                    className="btn btn-sm fw-bold btn-warning mb-3 me-3"
                    onClick={() => router.get('/')}
                  >
                    Go to Dashboard
                  </button>
                  <button
                    className="btn btn-sm fw-bold btn-warning mb-3 me-3"
                    onClick={() => router.get('/support/create')}
                  >
                    Contact Support
                  </button>
                </div>
              </h2>
            </CardBody>
          </Card>
        </div>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
