import React, {useEffect, useState} from 'react'
import {Suspense} from 'react'
import {AuthInit} from '@metronic/modules/auth'
import {LayoutProvider, LayoutSplashScreen} from '@metronic/_metronic/layout/core'
import {ThemeModeProvider} from '@metronic/_metronic/partials/layout/theme-mode/ThemeModeProvider'
import {MasterInit} from '@metronic/_metronic/layout/MasterInit'
import {MasterLayoutLight} from "@metronic/_metronic/layout/LayoutsVariations/MasterLayoutLight.tsx"
import {ModalProvider} from "@metronic/Components/Modal"
import {toast, ToastContainer} from "react-toastify"
import 'react-toastify/dist/ReactToastify.css'
import {router, usePage} from "@inertiajs/react";
import _ from "lodash";
import {createEchoInstance} from "@metronic/ws.js"
import ErrorBoundary from "@metronic/Components/ErorrBoundary/ErrorBoundary.jsx"

let echoEventsConfState = false

function echoEventsConf(userId) {
  if (echoEventsConfState) return

  const echo = createEchoInstance()

  // Debugging connection events
  echo.connector.pusher.connection.bind('connected', function() {
    echo.private(`notification.${userId}`)
      .listen('UserNotification', () => router.reload())
      .listen('VerificationStatus', () => router.reload())
      .listen('UserPlanChanged', () => router.reload())
      .listen('BalanceChanged', () => router.reload())
      .listen('TicketUpdated', () => router.reload())
  });

  echoEventsConfState = true
}

const MainLight = ({children}) => {
  const {props, url} = usePage()
  const flash = props.flash
  const [userId, setUserId] = useState('')

  useEffect(() => {
    if (props) {
      setUserId(props.auth?.user?.id)
    }
  }, [props])

  useEffect(() => {
    if (userId) {
      echoEventsConf(userId)
    }
  }, [userId])

  useEffect(() => {
    console.log('general props', props);
    if (_.size(props.errors)) {
      for (let error in props.errors) {
        toast.error(props.errors[error])
      }
    } else if (flash) {
      toast.success(flash.message)
    }
  }, [props])

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ErrorBoundary urlKey={url}>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <ModalProvider>
                <MasterLayoutLight>
                  {children}
                </MasterLayoutLight>
                <ToastContainer />
                <MasterInit />
              </ModalProvider>
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </ErrorBoundary>
    </Suspense>
  )
}

export default MainLight
